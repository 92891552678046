<template>
    <v-container fluid>
        <v-form @submit.prevent="getCoffeeHouseTables('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'coffee_house_tables.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_coffee_house_table') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'coffee_house_table.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="coffee_house_table" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="coffee_house_table" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-table-chair theme--light"
                                                  :label="$t('coffee_house_table')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="coffee_house"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="coffee_house" :items="itemCoffeeHouse"
                                              :error-messages="errors" :disabled="loadingCoffeeHouse" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-coffee-maker" :label="$t('coffee_house')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="type_table"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="type_table" :items="itemTypeTable"
                                              :error-messages="errors" :disabled="loadingTypeTable" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-table-furniture" :label="$t('type_table')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="coffee_house_tablesItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalCoffeeHouseTables" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.number="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.number }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!can(['administrator'])"
                                       @click="editCoffeeHouseTable(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteCoffeeHouseTable(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import {mask} from 'vue-the-mask'
import debounce from "lodash/debounce";

export default {
    name: "CoffeeHouseTables",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            sortBy: "number",
            sortDir: true,
            loading: false,
            loadingCoffeeHouse: false,
            coffee_house: null,
            itemCoffeeHouse: [],
            loadingTypeTable: false,
            type_table: null,
            itemTypeTable: [],
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalCoffeeHouseTables: 0,
            coffee_house_table: null,
            coffee_house_tablesItems: [],
            headers: [
                {
                    text: this.$t('coffee_house_table'),
                    align: "left",
                    sortable: true,
                    value: "number"
                },
                {
                    text: this.$t('coffee_house_name'),
                    align: "left",
                    sortable: false,
                    value: "coffee_house_table"
                },
                {
                    text: this.$t('type_table'),
                    align: "left",
                    sortable: false,
                    value: "type_table"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "active",
                    width: 120,
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            first_download: 1,
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),

    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getCoffeeHouse()
        await this.getTypeTable()
        await this.getCoffeeHouseTables('search')
    },
    watch: {
        options: {
            handler() {
                if(this.first_download === 0){
                    this.getCoffeeHouseTables()
                }
                this.first_download = 0;
            },
            deep: false
        },
    },
    methods: {
        editCoffeeHouseTable(item) {
            this.$router.push({
                name: 'coffee_house_table.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getCoffeeHouseTables(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.coffee_house_table) {
                params.coffee_house_table = this.coffee_house_table
            }
            if (this.coffee_house) {
                if (this.coffee_house.id) {
                    params.coffee_house =  this.coffee_house.id
                } else {
                    params.coffee_house =  this.coffee_house
                }
            }
            if (this.type_table) {
                if (this.type_table.id) {
                    params.type_table =  this.type_table.id
                } else {
                    params.type_table =  this.type_table
                }
            }
            params.active = this.active

            await this.$http
                .get("admin/coffee_house_table", {
                    params: params,
                })
                .then(res => {
                    this.coffee_house_tablesItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalCoffeeHouseTables = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.coffee_house_tablesItems = []
                    this.totalCoffeeHouseTables = 0
                    this.$toastr.error(this.$t('failed_to_get_list_coffee_house_tables'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteCoffeeHouseTable(item) {
            if (confirm(this.$t('delete_coffee_house_table'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/coffee_house_table/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('coffee_house_table_has_been_deleted'))
                        this.getCoffeeHouseTables()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('coffee_house_table_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getCoffeeHouse() {
            this.loadingCoffeeHouse = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`admin/coffee_house/`, {params: params})
                .then(res => {
                    this.itemCoffeeHouse = res.body.data
                    this.coffee_house = parseInt(this.$route.params.coffee_house) ?? null
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_coffee_houses'));
                    this.itemCoffeeHouse = []

                })
                .finally(end => {
                    this.loadingCoffeeHouse = false
                });
        },
        async getTypeTable() {
            this.loadingTypeTable = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/type_table/`, {params: params})
                .then(res => {
                    this.itemTypeTable = res.body.data
                    this.type_table = parseInt(this.$route.params.type_table) ?? null
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_coffee_houses'));
                    this.itemTypeTable = []

                })
                .finally(end => {
                    this.loadingTypeTable = false
                });
        },

    }
}
</script>
